.button {
  composes: flex_center from "../../shared/layout.module.css";
  border: solid;
  width: 160px;
  margin: 20px 10px;
  padding: 5px 5px;
  text-align: left;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  font-size: 10px;
  text-transform: uppercase;
  background-color: black;
  transition: background-color 0.25s linear;
}

.icon {
  width: 24px;
  margin-right: 8px;
  font-size: 28px;
}

.button:hover {
  background-color: #333;
}

.bigText {
  font-size: 17px;
  text-transform: capitalize;
}

.smallText {
  font-size: 11px;
}

@media screen and (max-width: 800px){
  .button {
    width: 140px;
  }

  .icon {
    width: 18px;
  }

  .bigText {
    font-size: 14px;
  }

  .smallText {
    font-size: 9px;
  }
}