.logoContainerDiv {
    padding-top: 16px;
    padding-left: 16px;
    background-color: #1D1628;
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1D1628;
    height: 70vh;
}

.form_background {
    background-color: #140f1c;
    padding: 40px 60px;
    width: calc(200px + 20vw);
}

.label {
    text-align: left;
    display: block;
    font-size: 20px;
    font-weight: 200;
}

.h2 {
    font-size: 40px;
    color: white;
    margin-bottom: 30px;
    font-weight: 600;
}

.input {
    width: 100%;
    padding: 16px 10px;
    margin: 10px 0;
    margin-bottom: 20px;
    border: 1px solid white;
    background-color: transparent;
    box-sizing: border-box;
    display: block;
    border-radius: 16px;
}

.button {
    width: 100%;
    background: #8640FF;
    color: #fff;
    border: 0;
    height: 60px;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 32px;
    font-size: 16px;
}

@media screen and (max-width: 800px) {
    .h2 {
        font-size: 30px;
    }

    .label {
        font-size: 16px;
    }
}