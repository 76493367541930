* {
  padding: 0;
  margin: 0;
  color: white;
  /* box-sizing: border-box;
  word-wrap: break-word; */
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif;
}

body {
  --swiper-theme-color: #fff;
  --swiper-pagination-bullet-inactive-color: #fff;
  /* --swiper-pagination-progressbar-bg-color: #fff */
}

body {
  background-color: #1D1628;
}

/* FORM CSS */


.swiper-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}