.logoDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logoImg {
    height: 64px;
    width: 64px;
    padding-right: 12px;
}

.logoText {
    font-size: calc(12px + 1vw);
    font-weight: 500;
}

.a {
    text-decoration: none;
}

.a:link {
    text-decoration: none;
}

.a:visited {
    text-decoration: none;
}

.a:hover {
    text-decoration: none;
}

.a:active {
    text-decoration: none;
}