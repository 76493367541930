.feature {
  padding: 10px;
  font-size: calc(16px + 2vw);
  font-weight: 600;
}

.outerDiv {
  padding: 3vw 0;
}

.stepTitle {
  margin-top: 10px;
  font-weight: 500;
  font-size: calc(12px + 1.5vw);;
}

.stepDescription {
  padding: 10px;
  font-weight: 100;
  font-size: calc(8px + 1vw);
}

.slider {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10vw;
  /* flex-direction: column; */
}

.sliderInner {
  /* position: relative; */
  margin: 0 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image {
  object-fit: cover;
  display: block;
  width: 25vw;
  margin-bottom: 20px;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@media screen and (max-width: 800px){
  .image {
    width: 50%;
  }
}