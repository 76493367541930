.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex_horizontal {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.half_width {
  width: 50%;
}

@media screen and (max-width: 800px){
  .half_width {
    width: 100%;
  }
}