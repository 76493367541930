.cotainerDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 20px;
    padding-top: 40px;
}

.textRights {
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 200;
}

.iconsDiv {
    margin-top: 10px;
}


.youtube {
    background-color: #3C3C4B;
    width: 24px;
    height: 24px;
    padding: 10px;
    margin-right: 16px;
    border-radius: 8px;
}

.instagram {
    background-color: #3C3C4B;
    width: 24px;
    height: 24px;
    padding: 10px;
    border-radius: 8px;
}

.youtube:hover {
    background-color: #FF0000;
}

.instagram:hover {
    background-color: #833AB4;
}

.sectionFooter {
    background-color: #0d0c0f;
}