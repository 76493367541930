.logoContainerDiv {
  margin-top: 16px;
  margin-left: 16px;
}

.bigText {
  padding: 10px;
  font-size: calc(24px + 3vw);
}

.titleDescriptionText {
  padding: 10px;
  padding-bottom: 50px;
  font-weight: 100;
  font-size: calc(12px + 1.5vw);
}

.mainTitleBlock {
  padding: 10vw 0vw;
  padding-left: 5vw;
}

.screenBig {
  width: 35vw;
  z-index: 2;
  margin-bottom: -30vw;
}

.screenSmall {
  width: 35vw;
  margin-left: -20vw;
  margin-bottom: -40vw;
}

.sectionAppScreens {
  overflow: hidden;
  background-color: #1D1628
}

.sectionGallery {
  overflow: hidden;
  background-color: #140f1c;
}

@media screen and (max-width: 800px) {
  .screenBig {
    width: 50vw;
  }

  .screenSmall {
    width: 50vw;
    margin-left: -25vw;
    margin-bottom: -45vw;
  }

  .bigText {
    text-align: center;
  }

  .titleDescriptionText {
    text-align: center;
    padding-bottom: 10px;
  }

  .mainTitleBlock {
    align-items: center;
    justify-content: center;
    padding-bottom: 2vw;
    padding-right: 5vw;
  }
}