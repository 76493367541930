.errorDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
}

.img {
    height: 20vw;
    width: 20vw;
}

.text {
    margin-top: 10px;
    font-size: calc(12px + 1vw);
    font-weight: 200;
    text-align: center;
    width: 35vw;
}
